\<template>
    <div class="main-menu">
        <div class="main-menu__wrapper">
            <menu class="main-menu__menu">
                <li>
                    <nuxt-link to="/about-us">
                        About us
                    </nuxt-link>
                </li>
                <li>
                    <nuxt-link to="/our-impact">
                        Our impact
                    </nuxt-link>
                </li>

                <li>
                    <nuxt-link to="/brands">
                        Brands
                    </nuxt-link>
                </li>

                <li>
                    <nuxt-link to="/resources-and-tools">
                        Resource library
                    </nuxt-link>
                </li>

                <li>
                    <nuxt-link to="/support">
                        Support
                    </nuxt-link>
                </li>
            </menu>
            <div class="main-menu__footer">
                <a
                    class="btn btn--white"
                    href="https://memberhub.fairwear.org/s/"
                >
                    Memberhub
                </a>

                <nuxt-link
                    class="btn btn--primary"
                    to="/join-the-movement"
                >
                    Join the movement
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainMenu',

    watch: {
        $route() {
            this.$emit('closeMenu', false);
        }
    }
};
</script>

<style lang="less">
.main-menu {
    z-index: -1;
}
.main-menu__wrapper {
    position: absolute;
    height: calc(100% - 5rem);
    width: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-menu__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: -1px;
    border-top: 1px solid rgba(0, 0, 0, 0.13);

    a {
        display: block;
        padding: 1.5rem 2rem;

        .smallcaps-label();
    }
}

.main-menu__footer {
    display: flex;
    flex-direction: column;

    padding: 2rem 2rem 3rem;
    gap: .8125rem;
}
</style>
