export default {

    fetchMenu(context) {
        const menuFlat = [];
        const menu = [
            {
                id: 'column1',
                items: [
                    {
                        id: 1,
                        title: 'About us',
                        subtitle: 'What we stand for',
                        url: '/about-us',
                        items: [
                            {
                                id: 10,
                                title: 'Get to know Fair Wear',
                                url: '/about-us/get-to-know-fair-wear'
                            },
                            {
                                id: 11,
                                title: 'What we stand for',
                                url: '/about-us/labour-standards'
                            },
                            {
                                id: 12,
                                title: 'Our collaborations',
                                url: '/about-us/our-partners'
                            },
                            {
                                id: 13,
                                title: 'Our team',
                                url: '/about-us/our-team'
                            },
                            {
                                id: 14,
                                url: '/stories/category/news',
                                title: 'News updates'
                            }
                        ]
                    },
                    {
                        id: 2,
                        title: 'Brand engagement',
                        subtitle: 'Our members that move with us',
                        url: '/brands',
                        items: [
                            {
                                id: 20,
                                url: '/programmes/brands',
                                title: 'Working with brands'
                            },
                            {
                                id: 21,
                                title: 'All our member brands',
                                url: '/brands'
                            },
                            {
                                id: 22,
                                title: 'Become a member brand',
                                url: '/join-the-movement/become-a-member'
                            },
                            {
                                id: 23,
                                title: 'Former brands',
                                url: '/brands/former-members'
                            },
                            {
                                id: 24,
                                title: 'Brand performance checks',
                                url: '/programmes/brand-performance-checks'
                            },
                            {
                                id: 25,
                                title: 'The HRDD Academy',
                                url: '/join-the-movement/enabling-garment-and-footwear-brands-to-conduct-hrdd/'
                            }
                        ]
                    }
                ]
            },
            {
                id: 'column2',
                items: [
                    {
                        id: 3,
                        title: 'Our impact',
                        subtitle: 'See the impact we create',
                        url: '/our-impact',
                        items: [
                            {
                                id: 63,
                                url: '/our-impact/lobby-advocacy',
                                title: 'Lobby & Advocacy'
                            },
                            {
                                id: 31,
                                url: '/programmes/countries',
                                title: 'Countries'
                            },
                            {
                                id: 32,
                                url: '/programmes/workplace-education',
                                title: 'Workplace Education'
                            },
                            {
                                id: 33,
                                url: '/programmes/living-wage',
                                title: 'Living Wage'
                            },
                            {
                                id: 34,
                                url: '/programmes/gender',
                                title: 'Gender'
                            },
                            {
                                id: 35,
                                url: '/programmes/complaints',
                                title: 'Complaints'
                            },
                            {
                                id: 36,
                                url: '/programmes/dialogue',
                                title: 'Social Dialogue'
                            }
                        ]
                    },
                    {
                        id: 4,
                        title: 'Resources & tools',
                        subtitle: 'Knowledge sharing',
                        url: '/resources-and-tools',
                        items: [
                            {
                                id: 48,
                                title: 'Social dialogue and FoA resources',
                                url: '/resources-and-tools/social-dialogue-and-foa-resources'
                            },
                            {
                                id: 40,
                                title: 'Wage ladder',
                                url: '/resources-and-tools/wage-ladder'
                            },
                            {
                                id: 41,
                                title: 'Factory guide',
                                url: '/resources-and-tools/factory-guide'
                            },
                            {
                                id: 42,
                                title: 'Resource documents',
                                url: '/resources-and-tools/resource-documents'
                            },
                            {
                                id: 43,
                                title: 'Fair price app',
                                url: '/resources-and-tools/fair-price-app'
                            },
                            {
                                id: 44,
                                title: 'Gender fact sheets',
                                url: '/resources-and-tools/gender-fact-sheets'
                            },
                            {
                                id: 45,
                                title: 'Factories our members source from',
                                url: '/resources-and-tools/factories'
                            },
                            {
                                id: 47,
                                title: 'Fair working hours guide',
                                url: '/resources-and-tools/fair-working-hours-guide'
                            },
                            {
                                id: 49,
                                title: 'Employment is freely chosen',
                                url: '/resources-and-tools/employment-is-freely-chosen'
                            },
                            {
                                id: 46,
                                title: 'Covid-19 Dossier',
                                url: '/covid-19-dossier',
                                highlighted: true
                            }
                        ]
                    }
                ]
            },
            {
                id: 'column3',
                items: [
                    {
                        id: 5,
                        title: 'Join the movement',
                        subtitle: 'See what you can do',
                        url: '/join-the-movement',
                        items: [
                            {
                                id: 51,
                                title: 'How to Wear Fair',
                                url: '/join-the-movement/how-to-wear-fair'
                            },
                            {
                                id: 52,
                                title: 'Vacancies',
                                url: '/join-the-movement/vacancies'
                            }
                        ]
                    },
                    {
                        id: 6,
                        title: 'Support',
                        subtitle: 'FAQ, press and contact',
                        url: '/support',
                        items: [
                            {
                                id: 60,
                                title: 'FAQ',
                                url: '/support/frequently-asked-questions'
                            },
                            {
                                id: 61,
                                title: 'Contact',
                                url: '/support/contact'
                            },
                            {
                                id: 62,
                                title: 'Press and media',
                                url: '/support/press'
                            }
                        ]
                    },
                    {
                        id: 7,
                        title: 'Member hub',
                        url: 'https://memberhub.fairwear.org/s/',
                        highlight: true
                    }
                ]
            }
        ];

        context.commit('set', menu);

        // loop columns for flat menu
        menu.forEach(function(column) {
            // loop main items
            column.items.forEach(function(item) {
                menuFlat.push(item);
            });
        });

        context.commit('setFlat', menuFlat);

        return menu;
    },

    getSiblings(context, slug) {

    },

    getitems(context, slug) {

    }
};
