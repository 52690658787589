<template>
    <div>
        <navigation
            @navigation-expanded="expandNavigation"
            @navigation-collapsed="collapseNavigation"
            @search-expanded="expandSearch"
            @search-collapsed="collapseSearch"
        />
    </div>
</template>

<script>

import Navigation from '~/patterns/organisms/navigation/navigation';

export default {
    components: {
        Navigation
    },

    methods: {
        expandNavigation() {
            this.$emit('navigation-expanded');
        },

        collapseNavigation() {
            this.$emit('navigation-collapsed');
        },

        expandSearch() {
            this.$emit('search-expanded');
        },

        collapseSearch() {
            this.$emit('search-collapsed');
        }
    }
};
</script>
