<template>
    <svg
        class="hamburger"
        width="32px"
        height="21px"
        viewBox="0 0 32 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="A_Primer/Icon/Icon-Menu" transform="translate(0.000000, -6.000000)" fill="#009E9D">
                <path class="hamburger__line hamburger__line1" d="M3.5,6 L28.5,6 C29.3284271,6 30,6.67157288 30,7.5 L30,7.5 C30,8.32842712 29.3284271,9 28.5,9 L3.5,9 C2.67157288,9 2,8.32842712 2,7.5 L2,7.5 C2,6.67157288 2.67157288,6 3.5,6 Z" />
                <path class="hamburger__line hamburger__line2" d="M3.5,15 L28.5,15 C29.3284271,15 30,15.6715729 30,16.5 L30,16.5 C30,17.3284271 29.3284271,18 28.5,18 L3.5,18 C2.67157288,18 2,17.3284271 2,16.5 L2,16.5 C2,15.6715729 2.67157288,15 3.5,15 Z" />
                <path class="hamburger__line hamburger__line3" d="M3.5,24 L28.5,24 C29.3284271,24 30,24.6715729 30,25.5 L30,25.5 C30,26.3284271 29.3284271,27 28.5,27 L3.5,27 C2.67157288,27 2,26.3284271 2,25.5 L2,25.5 C2,24.6715729 2.67157288,24 3.5,24 Z" />
            </g>
        </g>
    </svg>
</template>

<script>

export default {
};

</script>

<style lang="less" scoped>
    @import 'hamburger.less';
</style>
