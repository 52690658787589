<template>
    <svg
        class="close"
        width="22px"
        height="21px"
        viewBox="0 0 22 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="A_Primer/Icon/Icon-Close" transform="translate(-5.000000, -6.000000)" fill="#009E9D">
                <path d="M16,14.3786797 L23.7781746,6.60050506 C24.363961,6.01471863 25.3137085,6.01471863 25.8994949,6.60050506 C26.4852814,7.1862915 26.4852814,8.13603897 25.8994949,8.72182541 L18.1213203,16.5 L25.8994949,24.2781746 C26.4852814,24.863961 26.4852814,25.8137085 25.8994949,26.3994949 C25.3137085,26.9852814 24.363961,26.9852814 23.7781746,26.3994949 L16,18.6213203 L8.22182541,26.3994949 C7.63603897,26.9852814 6.6862915,26.9852814 6.10050506,26.3994949 C5.51471863,25.8137085 5.51471863,24.863961 6.10050506,24.2781746 L13.8786797,16.5 L6.10050506,8.72182541 C5.51471863,8.13603897 5.51471863,7.1862915 6.10050506,6.60050506 C6.6862915,6.01471863 7.63603897,6.01471863 8.22182541,6.60050506 L16,14.3786797 Z" />
            </g>
        </g>
    </svg>
</template>

<script>

export default {
};

</script>

<style lang="less" scoped>
    @import 'close.less';
</style>
