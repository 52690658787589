'use strict';

import config from '~/config/config';

export default function canonical({ path }) {
    const domain = config.self.url;

    if (path === '/') {
        return `${domain}/`;
    }

    if (path.endsWith('/')) {
        return `${domain}${path}`;
    }

    return `${domain}${path}/`;
}
