export default {
    setTitle(state, title) {
        state.title = title;
    },

    setTitleShort(state, titleShort) {
        state.titleShort = titleShort;
    },

    setFilter(state, filter) {
        state.filter = filter;
    }
};
