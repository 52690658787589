import VueCookieConsent from '@digitalnatives/cookie-consent';
import Vue from 'vue';

Vue.use(VueCookieConsent, {
    categories: [
        {
            id: 'all',
            required: true
        }
    ],
    expires: 365
});

export default function ({ req }) {
    if (typeof req !== 'undefined') {
        Vue.$cookieConsent.setServerRequest(req);
    }
}
