<template>
    <div
        class="layout"
        :class="{
            'layout--navigation-expanded': navigationExpanded,
            'layout--search-expanded': searchExpanded
        }"
    >
        <site-header
            @navigation-expanded="expandNavigation"
            @navigation-collapsed="collapseNavigation"
            @search-expanded="expandSearch"
            @search-collapsed="collapseSearch"
        />

        <not-found :message="message" />
    </div>
</template>

<script>

import SiteHeader from '~/patterns/organisms/site-header/site-header';
import NotFound from '~/pages/404';

export default {
    components: {
        SiteHeader,
        NotFound
    },

    props: {
        error: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            navigationExpanded: false,
            searchExpanded: false
        };
    },

    computed: {
        statusCode() {
            return (this.error && this.error.statusCode) || 500;
        },
        message() {
            if (this.error.message && !this.error.message.includes('statusCode')) {
                return this.error.message;
            }
            return undefined;
        }
    },

    methods: {
        expandNavigation() {
            this.navigationExpanded = true;
        },

        collapseNavigation() {
            this.navigationExpanded = false;
        },

        expandSearch() {
            this.searchExpanded = true;
        },

        collapseSearch() {
            this.searchExpanded = false;
        }
    }

};
</script>
