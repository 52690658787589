/**
 * Returns the hero image for different post types
 */
export default {
    methods: {
        getHeroImage(post) {

            let imageUrl = '';

            if(post.images && post.images.hero) {
                // Wordpress post types
                imageUrl = post.images.hero;
            } else if(post.company && post.company.header_path) {
                // Member Hub brands
                imageUrl = post.company && post.company.header_sizes ? post.company.header_sizes['hero-large'] : '';
            }

            return imageUrl;
        }
    }
};
