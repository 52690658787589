<template>
    <div>
        <header class="layout__header">
            <hero :title="message" :image="''" />
        </header>
        <div class="layout__main">
            <div class="site-article">
                <p>
                    Something went wrong. The page you were looking for does not exist (anymore)
                    or has been moved to another location. Please check if you entered the web
                    address correctly. You can use the navigation or go to the <nuxt-link to="/">homepage</nuxt-link>.
                </p>
            </div>
        </div>
    </div>
</template>

<script>

import getHeroImage from '~/mixins/get-hero-image';
import Hero from '~/patterns/molecules/hero/hero.vue';

export default {
    components: {
        Hero
    },

    mixins: [getHeroImage],

    props: {
        message: {
            type: String,
            default: 'Oops! This page could not be found'
        }
    }
};

</script>
