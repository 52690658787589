export default {
    self: {
        url: 'https://fairwear.ganymede.digitalnatives.nl'
    },
    api: {
        url: 'https://api.fairwear.ganymede.digitalnatives.nl',
        memberHub: 'https://fwf--develop.sandbox.my.site.com/public/services/apexrest',
    },
    bugsnagId: '917383bb604596093c2e8e3343a58bc4',
    algolia: {
        applicationId: '3GSLNUTHJ9',
        apiKey: '51550019a3204e4b4c7c05cb39be841a'
    },
    showTransparencyPolicy: true,
    googleTagManagerId: 'GTM-MCXXCFZ'
};
