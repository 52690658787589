/* eslint-disable */

'use strict';

import config from '~/nuxt.config';
import axios from '~/plugins/axios';
import Bugsnag from '@bugsnag/js';

async function get(path, api = 'wp', params = {}) {
    const { data } = await axios.get(
        `${config.axios.baseURL}/wp-json/${api}/v2/${path}`,
        { params }
    );

    return data;
};

function getPosts (params) {
    return get('posts', 'fwf', params);
};

async function getPage(slug) {
    const pages = await getPosts(
        {
            post_type: 'page',
            pagename: slug
        }
    );

    return pages[0];
};

const getComplaint = function (slug) {
    return getPosts(
        {
            post_type: 'complaint',
            name: slug
        }
    ).then(
        complaints =>
        {
            return complaints[0];
        }
    );
};

export function sendComplaint ({ factoryId, country, name, email, phone, type, labour_standards, accused_party, message }) {
   return axios.post(`${config.axios.baseURL}/wp-json/fwf/v2/complaints/factory/`, {
       factoryId,
       country,
       name,
       email,
       phone,
       type,
       labour_standards,
       accused_party,
       message
   }).then(({data}) => data);
}

const getCountry = function (slug) {
    return getPosts(
        {
            post_type: 'country',
            name: slug
        }
    ).then(
        countries =>
        {
            return countries[0];
        }
    );
};

const getCountries = function (slug) {
    return getPosts(
        {
            post_type: 'country',
            post_parent: 0,
            posts_per_page: -1,
            orderby: 'name',
            order: 'ASC'
        }
    ).then(
        pages =>
        {
            return pages;
        }
    );
};

const getResourcesAndTools = function (slug) {
    return getPosts(
        {
            post_type: 'tool',
            post_parent: 0,
            posts_per_page: -1,
            orderby: 'name',
            order: 'ASC'
        }
    ).then(
        pages =>
        {
            return pages;
        }
    );
};

export async function getResourceOrTool(slug) {
    const data = await get('posts', 'fwf', {
        name: slug,
        post_type: 'tool'
    });

    return data[0];
};

async function getBreadcrumbs(id, slug) {
    return await get('breadcrumbs', 'fwf', {
        slug,
        id
    });
};

const getBrands = function () {
    return get('brands');
};

const getCategories = async function () {
    return await get('categories/?per_page=100').then(
        response =>
        {
            return response.filter(
                function (cat) {
                    return cat.count ? cat.count : cat.category_count;
                }
            );
        }
    );
};

const getProgrammes = function () {
    return getPosts(
        {
            post_type: 'program',
            post_parent: 0,
            posts_per_page: 4
        }
    ).then(
        programmes =>
        {
            return programmes;
        }
    );
};

const getProgrammesByIds = function (ids = []) {
    return getPosts(
        {
            post_type: 'program',
            post_parent: 0,
            posts_per_page: -1,
            post__in: ids
        }
    );
};

const getResource = function (slug) {
    return getPosts(
        {
            post_type: 'resource',
            name: slug
        }
    ).then(
        resources =>
        {
            return resources[0];
        }
    );
};

async function getBrand(slug) {
    const data = await get('brands', 'wp', {
        '_embed': '',
        slug
    });

    return data[0];
};

async function getProgramme(slug) {
    const data  = await getPosts({
        name: slug,
        post_type: 'program',
    });

    return data[0];
};

function getStories(params) {
    return get('stories', 'fwf', {
        '_embed': '',
        ...params
    });
};

const getStory = async function (slug) {
    return await axios.get(config.axios.baseURL + '/wp-json/fwf/v2/stories/?slug='+slug)
    .then(
        response =>
        {
                return response.data[0];
            }
        );
};

const getTerms = function(params) {
    return axios.get(config.axios.baseURL + '/wp-json/fwf/v2/terms')
        .then(response => {
            return response.data;
        });
};

const getToolsByProgramId = function(postId) {
    return axios.get(config.axios.baseURL + '/wp-json/fwf/v2/tools?post_type=program&id=' + postId)
        .then(response => {
            return response.data;
        });
}

const getFeaturedImage = function(post, size) {
    let media = {
        source_url: false
    };

    if (typeof post !== 'undefined' && post._embedded && post._embedded['wp:featuredmedia'] && typeof post._embedded['wp:featuredmedia'][0] === 'object') {
        media = post._embedded['wp:featuredmedia'][0];

        if (size &&
            typeof media.media_details !== 'undefined' &&
            typeof media.media_details.sizes !== 'undefined' &&
            typeof media.media_details.sizes[size] !== 'undefined'
        ) {
            media = media.media_details.sizes[size];
        }
    }

    return media.source_url;
};

export async function getFrontPage() {
    try {
        return await get('pages/front-page', 'fwf');
    } catch (e) {
        Bugsnag.notify(e);
    }

    return {};
}

export async function getPostById(postId)
{
    try {
        return getPosts(
            {
                id: postId
            }
        ).then(
            post =>
            {
                return post[0];
            }
        );
    } catch (e) {
        Bugsnag.notify(e);
    }

    return {};
}

export default {
    get,
    getPage,
    getBrands,
    getBrand,
    getStory,
    getStories,
    getProgramme,
    getProgrammes,
    getProgrammesByIds,
    getFeaturedImage,
    getCategories,
    getBreadcrumbs,
    getCountries,
    getCountry,
    getComplaint,
    getResource,
    getResourcesAndTools,
    getResourceOrTool,
    getPosts,
    getTerms,
    getToolsByProgramId
};
