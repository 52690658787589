// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/decoration/pattern-pink.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{min-height:100vh}.font-primary,.font-primary-regular{font-family:Barlow,helvetica,arial,sans-serif;font-weight:400}.cta-movement{background:#f2aac8;max-width:100svw;overflow:hidden}.cta-movement__items{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");padding:5rem 1rem;text-align:center}.cta-movement__brands{display:flex;gap:3rem}.cta-movement__title{font-size:3rem;font-weight:700;margin:0 auto 3rem;max-width:40rem}.cta-movement__btn{background-color:#fff;text-align:left;width:100%}@media screen and (min-width:722px){.cta-movement__btn{text-align:center;width:auto}}@keyframes scroll{0%{transform:translateX(0)}to{transform:translateX(-574.667px)}}.cta-movement__brands{animation:scroll 10s linear infinite;padding:3rem 0}.cta-movement__brand-image{height:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
