<template>
    <svg
        class="search-icon"
        width="27px"
        height="27px"
        viewBox="0 0 27 27"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-2.000000, -2.000000)" fill="#009E9D">
                <path d="M21.0020101,23.1233304 C19.0628185,24.6138559 16.6348943,25.5 14,25.5 C7.64872538,25.5 2.5,20.3512746 2.5,14 C2.5,7.64872538 7.64872538,2.5 14,2.5 C20.3512746,2.5 25.5,7.64872538 25.5,14 C25.5,16.6348943 24.6138559,19.0628185 23.1233304,21.0020101 L28.0202201,25.8988997 C28.4107443,26.289424 28.4107443,26.922589 28.0202201,27.3131133 L27.3131133,28.0202201 C26.922589,28.4107443 26.289424,28.4107443 25.8988997,28.0202201 L21.0020101,23.1233304 Z M14,22.5 C18.6944204,22.5 22.5,18.6944204 22.5,14 C22.5,9.30557963 18.6944204,5.5 14,5.5 C9.30557963,5.5 5.5,9.30557963 5.5,14 C5.5,18.6944204 9.30557963,22.5 14,22.5 Z" transform="translate(15.613663, 15.613663) scale(-1, 1) translate(-15.613663, -15.613663) " />
            </g>
        </g>
    </svg>
</template>

<script>

export default {
};

</script>

<style lang="less" scoped>
    @import 'search-icon.less';
</style>
