import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6b53bc4c = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _dfd231c6 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _6d13a4f3 = () => interopDefault(import('../pages/join-the-movement/index.vue' /* webpackChunkName: "pages/join-the-movement/index" */))
const _26af3b78 = () => interopDefault(import('../pages/our-impact/index.vue' /* webpackChunkName: "pages/our-impact/index" */))
const _58f9ed68 = () => interopDefault(import('../pages/programmes/index.vue' /* webpackChunkName: "pages/programmes/index" */))
const _cefaa3bc = () => interopDefault(import('../pages/resources-and-tools/index.vue' /* webpackChunkName: "pages/resources-and-tools/index" */))
const _4bb1b938 = () => interopDefault(import('../pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _244747b9 = () => interopDefault(import('../pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _729dbbb4 = () => interopDefault(import('../pages/about-us/our-team/index.vue' /* webpackChunkName: "pages/about-us/our-team/index" */))
const _6da5a185 = () => interopDefault(import('../pages/brands/former-members/index.vue' /* webpackChunkName: "pages/brands/former-members/index" */))
const _759f564d = () => interopDefault(import('../pages/programmes/countries/index.vue' /* webpackChunkName: "pages/programmes/countries/index" */))
const _249741fb = () => interopDefault(import('../pages/resources-and-tools/factories/index.vue' /* webpackChunkName: "pages/resources-and-tools/factories/index" */))
const _60868648 = () => interopDefault(import('../pages/resources-and-tools/resource-documents/index.vue' /* webpackChunkName: "pages/resources-and-tools/resource-documents/index" */))
const _32510e65 = () => interopDefault(import('../pages/resources-and-tools/factories/thank-you.vue' /* webpackChunkName: "pages/resources-and-tools/factories/thank-you" */))
const _9dea6c18 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6bceb870 = () => interopDefault(import('../pages/programmes/countries/_slug/index.vue' /* webpackChunkName: "pages/programmes/countries/_slug/index" */))
const _2f2c1686 = () => interopDefault(import('../pages/stories/category/_slug/index.vue' /* webpackChunkName: "pages/stories/category/_slug/index" */))
const _046dce43 = () => interopDefault(import('../pages/resources-and-tools/factories/_factory/contact.vue' /* webpackChunkName: "pages/resources-and-tools/factories/_factory/contact" */))
const _6bb45898 = () => interopDefault(import('../pages/brands/_slug/index.vue' /* webpackChunkName: "pages/brands/_slug/index" */))
const _b9a38aba = () => interopDefault(import('../pages/programmes/_slug/index.vue' /* webpackChunkName: "pages/programmes/_slug/index" */))
const _386e07dd = () => interopDefault(import('../pages/resources-and-tools/_slug/index.vue' /* webpackChunkName: "pages/resources-and-tools/_slug/index" */))
const _2d5909c5 = () => interopDefault(import('../pages/resources/_slug/index.vue' /* webpackChunkName: "pages/resources/_slug/index" */))
const _069c2b1a = () => interopDefault(import('../pages/stories/_slug/index.vue' /* webpackChunkName: "pages/stories/_slug/index" */))
const _206b1fa3 = () => interopDefault(import('../pages/wp-content/_.vue' /* webpackChunkName: "pages/wp-content/_" */))
const _4099f135 = () => interopDefault(import('../pages/ul/_.vue' /* webpackChunkName: "pages/ul/_" */))
const _2b3d71bc = () => interopDefault(import('../pages/_slug/_.vue' /* webpackChunkName: "pages/_slug/_" */))
const _de572ffe = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _6b53bc4c,
    name: "404"
  }, {
    path: "/brands",
    component: _dfd231c6,
    name: "brands"
  }, {
    path: "/join-the-movement",
    component: _6d13a4f3,
    name: "join-the-movement"
  }, {
    path: "/our-impact",
    component: _26af3b78,
    name: "our-impact"
  }, {
    path: "/programmes",
    component: _58f9ed68,
    name: "programmes"
  }, {
    path: "/resources-and-tools",
    component: _cefaa3bc,
    name: "resources-and-tools"
  }, {
    path: "/stories",
    component: _4bb1b938,
    name: "stories"
  }, {
    path: "/styleguide",
    component: _244747b9,
    name: "styleguide"
  }, {
    path: "/about-us/our-team",
    component: _729dbbb4,
    name: "about-us-our-team"
  }, {
    path: "/brands/former-members",
    component: _6da5a185,
    name: "brands-former-members"
  }, {
    path: "/programmes/countries",
    component: _759f564d,
    name: "programmes-countries"
  }, {
    path: "/resources-and-tools/factories",
    component: _249741fb,
    name: "resources-and-tools-factories"
  }, {
    path: "/resources-and-tools/resource-documents",
    component: _60868648,
    name: "resources-and-tools-resource-documents"
  }, {
    path: "/resources-and-tools/factories/thank-you",
    component: _32510e65,
    name: "resources-and-tools-factories-thank-you"
  }, {
    path: "/",
    component: _9dea6c18,
    name: "index"
  }, {
    path: "/programmes/countries/:slug",
    component: _6bceb870,
    name: "programmes-countries-slug"
  }, {
    path: "/stories/category/:slug",
    component: _2f2c1686,
    name: "stories-category-slug"
  }, {
    path: "/resources-and-tools/factories/:factory?/contact",
    component: _046dce43,
    name: "resources-and-tools-factories-factory-contact"
  }, {
    path: "/brands/:slug",
    component: _6bb45898,
    name: "brands-slug"
  }, {
    path: "/programmes/:slug",
    component: _b9a38aba,
    name: "programmes-slug"
  }, {
    path: "/resources-and-tools/:slug",
    component: _386e07dd,
    name: "resources-and-tools-slug"
  }, {
    path: "/resources/:slug",
    component: _2d5909c5,
    name: "resources-slug"
  }, {
    path: "/stories/:slug",
    component: _069c2b1a,
    name: "stories-slug"
  }, {
    path: "/wp-content/*",
    component: _206b1fa3,
    name: "wp-content-all"
  }, {
    path: "/ul/*",
    component: _4099f135,
    name: "ul-all"
  }, {
    path: "/:slug/*",
    component: _2b3d71bc,
    name: "slug-all"
  }, {
    path: "/*",
    component: _de572ffe,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
