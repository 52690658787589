'use strict';

import canonical from '../utils/canonical';

export default function ({ route, res }) {
    if (process.server) {
        const url = canonical(route);
        res.setHeader('Link', `<${url}>; rel="canonical"`);
    }
}
