const redirects = require('./301.json');

export default function (req, res, next) {
    const requestUrl = req.url.endsWith('/') ? req.url.slice(0, -1) : req.url;
    const redirect = redirects.find(r => r.from === requestUrl);

    if (redirect) {
        res.writeHead(301, { Location: redirect.to });
        res.end();
    } else {
        next();
    }
}
