<template>
    <footer class="main-footer">
        <section class="main-footer__wrapper">
            <h2 class="main-footer__lead">together, we're making clothing <em>fair for everyone</em></h2>

            <ul class="main-footer__social-links">
                <li>
                    <a target="_blank" href="https://www.linkedin.com/company/fair-wear-foundation/">
                        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <g fill-rule="evenodd">
                                <path d="m2.38080769.12817949c1.31307693 0 2.38025641 1.06717948 2.38025641 2.38076923 0 1.3151282-1.06717948 2.38230769-2.38025641 2.38230769-1.31666666 0-2.38076923-1.06717949-2.38076923-2.38230769 0-1.31358975 1.06410257-2.38076923 2.38076923-2.38076923zm-2.05474359 19.78512821h4.10910257v-13.21512821h-4.10910257z" />
                                <path d="m7.01121795 6.69819231h3.94025645v1.80487179h.0561538c.5483333-1.03858974 1.8884615-2.13435897 3.8866667-2.13435897 4.1561538 0 4.9239743 2.73628205 4.9239743 6.29602567v7.2485897h-4.1025641v-6.426282c0-1.5334616-.0301282-3.50461542-2.135-3.50461542-2.1374359 0-2.4634615 1.66974362-2.4634615 3.39346152v6.5374359h-4.10602565z" />
                            </g>
                        </svg>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.facebook.com/FairWearFoundation/">
                        <svg title="Facebook" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.65 24V12.498H0V8.191h2.65V5.605C2.65 2.09 4.143 0 8.394 0h3.537v4.31h-2.21c-1.655 0-1.765.601-1.765 1.725L7.95 8.19h4.007l-.47 4.307H7.95V24h-5.3z" fill="#fff" fill-rule="evenodd" />
                        </svg>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://twitter.com/fairwear_org">
                        <svg
                            title="X"
                            width="32"
                            height="30"
                            viewBox="0 0 32 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path id="path1009" d="M0.0780193 0L12.4329 16.5196L0 29.9507H2.79814L13.6831 18.1916L22.4778 29.9507H32L18.95 12.5019L30.5225 0H27.7243L17.6998 10.83L9.6002 0H0.0780193ZM4.19289 2.06111H8.5674L27.8845 27.8893H23.51L4.19289 2.06111Z" fill="white" />
                        </svg>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.instagram.com/fairwearfoundation/">
                        <svg
                            title="Instagram"
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path id="Vector" d="M18.8246 0H7.17485C3.21864 0 0 3.2188 0 7.17501V18.8248C0 22.7812 3.21864 25.9998 7.17485 25.9998H18.8246C22.7812 25.9998 25.9998 22.781 25.9998 18.8248V7.17501C26 3.2188 22.7812 0 18.8246 0ZM23.6931 18.8248C23.6931 21.5092 21.5092 23.693 18.8248 23.693H7.17485C4.49063 23.6931 2.30683 21.5092 2.30683 18.8248V7.17501C2.30683 4.49078 4.49063 2.30683 7.17485 2.30683H18.8246C21.509 2.30683 23.693 4.49078 23.693 7.17501V18.8248H23.6931Z" fill="white" />
                            <path id="Vector_2" d="M13.0003 6.30066C9.30612 6.30066 6.30078 9.306 6.30078 13.0002C6.30078 16.6942 9.30612 19.6993 13.0003 19.6993C16.6944 19.6993 19.6998 16.6942 19.6998 13.0002C19.6998 9.306 16.6944 6.30066 13.0003 6.30066ZM13.0003 17.3924C10.5783 17.3924 8.60761 15.422 8.60761 13C8.60761 10.5778 10.5781 8.60733 13.0003 8.60733C15.4224 8.60733 17.3929 10.5778 17.3929 13C17.3929 15.422 15.4223 17.3924 13.0003 17.3924Z" fill="white" />
                            <path id="Vector_3" d="M19.9807 4.34473C19.5363 4.34473 19.0997 4.52466 18.7858 4.83993C18.4704 5.15365 18.2891 5.59041 18.2891 6.0364C18.2891 6.481 18.4705 6.91761 18.7858 7.23288C19.0995 7.54661 19.5363 7.72808 19.9807 7.72808C20.4267 7.72808 20.8619 7.54661 21.1772 7.23288C21.4925 6.91761 21.6724 6.48085 21.6724 6.0364C21.6724 5.59041 21.4925 5.15365 21.1772 4.83993C20.8635 4.52466 20.4267 4.34473 19.9807 4.34473Z" fill="white" />
                        </svg>
                    </a>
                </li>
            </ul>

            <ul class="main-footer__navigation">
                <li>
                    <nuxt-link to="/our-impact">Our impact</nuxt-link>
                </li>
                <li>
                    <nuxt-link to="/about-us">About us</nuxt-link>
                </li>
                <li>
                    <nuxt-link to="/brands">Brands</nuxt-link>
                </li>
                <li>
                    <nuxt-link to="/support">Support</nuxt-link>
                </li>
            </ul>
        </section>
        <section class="main-footer__wrapper">
            <ul class="main-footer__legal">
                <li>
                    <nuxt-link to="/privacy-policy">How we deal with privacy</nuxt-link>
                </li>
                <!-- <li>
                    <nuxt-link to="/legal">Legal information</nuxt-link>
                </li> -->
            </ul>
        </section>
    </footer>
</template>

<script>
export default {
};
</script>

<style lang="less">
.main-footer {
    background: @color-tertiary;
    padding-top: 3.5rem;

    @media @q-md-min {
        padding-top: 5rem;
    }
}

.main-footer__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @media @q-xl-min {
        width: 100%;
        max-width: 100%;
    }

    @media @q-xxl-min {
        padding-left: 0;
        padding-right: 0;
    }
}

.main-footer__lead {
    .font-size(@font-size-xl, @font-size-xxxl);

    margin-top: 0;

    font-family: @font-family-secondary;
    font-weight: 500;

    text-align: center;
    padding: 0 1.5rem;
    margin-bottom: 2.5rem;

    @media @q-md-min {
        margin-bottom: 4.5rem;
    }

    &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;

        width: .5rem;
        height: 1.2em;
        margin-left: .7rem;
        transform: skewX(-18deg);
        background: @color-primary;
    }
}

.main-footer__navigation {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 0;
    margin-bottom: 3.5rem;

    @media @q-md-min {
        gap: 2rem;
        margin-bottom: 5rem;
        flex-direction: row;
    }
}

.main-footer__navigation li {
    margin-bottom: 1rem;

    @media @q-md-min {
        margin-bottom: 1rem;
    }
}

.main-footer__navigation a {
    position: relative;
    font-size: 1rem;
    white-space: nowrap;
    .smallcaps-label();

    &:after {
        content: '';

        position: absolute;
        left: 0;
        top: calc(100% + .2rem);

        height: .125rem;
        width: 100%;
        background: url('@{image-path}/underline-black.svg');
        transition: 200ms;
    }

    &:hover {
        &:after {
            background: url('@{image-path}/underline-black.svg');
            background-position-x: 1rem;
        }
    }
}

.main-footer__legal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 1.5rem;
    font-family: @font-family-secondary;
    background-image: url('@{image-path}/decoration/pattern-yellow-rotated.png');

    @media @q-sm-min {
        flex-direction: row;
        padding: 3rem;
    }
}

.main-footer__legal li {
    font-family: @font-family-secondary;

    @media @q-sm-min {
        text-transform: lowercase;
    }
}

.main-footer__legal a {
    font-size: 1rem;

    &:hover {
        text-decoration: underline;
    }
}

.main-footer__social-links {
    display: flex;
    flex-direction: row-reverse;
    gap: .5rem;
    margin-bottom: 2.5rem;

    @media @q-md-min {
        margin-bottom: 3.5rem;
    }
}

.main-footer__social-links a {
    display: block;
    background: @color-darkest;
    color: @color-lightest;
    width: 4rem;
    height: 3.25rem;
    text-align: center;

    transform: skew(-10deg);

    svg {
        width: 3.25rem;
        height: 3.25rem;
        padding: .8rem;
        transform: skew(10deg);

        path {
            fill: #ffffff;
        }
    }

    &:hover {
        svg {
            opacity: .75;
        }
    }
}

// * Reset list styles -- Might need to move this to list.less
.main-footer__navigation, .main-footer__legal, .main-footer__social-links {
    li {
        padding: 0;

        &:before {
            display: none;
        }
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}

</style>
