'use strict';

import WPAPI from '~/api/wordpress';
import axios from '~/plugins/axios';
import config from '~/nuxt.config';

const memberHubUrl = `${config.axios.memberHubURL}/api/v1.0`;

export const state = () => ({
    brands: []
});

export const getters = {
    brands: ({ brands }) => brands,
    total_brands: ({ brands }) => brands.length
};

export const actions = {
    async fetchBrands({ commit }) {
        const response = await axios.get(`${memberHubUrl}/brand`);

        if (response) {
            commit('set', response.data);
            return response.data;
        }
    },

    async fetchBySlug(context, slug) {
        // Get brand from member hub
        const MHData = await axios.get(`${memberHubUrl}/brand/${slug}`);

        if (MHData) {
            const memberHubBrand = MHData.data;

            if (!memberHubBrand.slug) {
                return;
            }

            const wpBrand = await WPAPI.getBrand(slug);

            // add wordpress information to the brand.
            if (wpBrand) {
                // add sorted related_resources if we have them
                if (wpBrand.meta && wpBrand.meta.related_resources && wpBrand.meta.related_resources[0] !== '') {
                    // Sort related resources by date DESC
                    memberHubBrand.resources = wpBrand.meta.related_resources.sort(
                        (a, b) => new Date(b.post_date) - new Date(a.post_date)
                    );
                }

                memberHubBrand.wordpressId = wpBrand.id;
            }

            return memberHubBrand;
        }

        return null;
    },

    getBrandById(context, id) {
        return axios.get(
            memberHubUrl + '/brand/' + id
        ).then((response) => {
            // add default wp stuff
            response.data.title = {
                rendered: response.data.name
            };
            response.data.meta = {};
            response.data.type = 'brand';

            return response.data;
        });
    },

    fetchBrandsByStatus(context, status) {
        return axios.get(
            memberHubUrl + '/brand/status/' + status
        ).then(
            (response) => {
                return response.data;
            }
        );
    }
};

export const mutations = {
    set(myState, brands) {
        myState.brands = brands;
    },
    add(myState, brand) {
        const brandExists = myState.brands.find(findBrand => brand.id === findBrand.id);

        if (!brandExists) {
            myState.brands.push(brand);
        }
    }
};
