export default {
    set(state, menu) {
        state.menu = menu;
    },
    setFlat(state, menu) {
        state.menuFlat = menu;
    },
    add(state, item) {
        state.menu.push(item);
    }
};
