import siteConfig from './config/config';
import {
    brandRoutes,
    countryRoutes,
    pageRoutes,
    storyRoutes,
    programRoutes,
    resourceRoutes,
    // termRoutes,
    toolRoutes
} from './helpers/sitemap';

export default {
    ssr: false,
    target: 'client',
    telemetry: false,
    modern: 'client',

    env: {
        APP_ENV: process.env.APP_ENV || 'production'
    },

    router: {
        middleware: ['canonical']
    },

    /*
     ** Headers of the page
     */
    head: {
        title: 'Fair Wear',
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            {
                hid: 'description',
                name: 'description',
                content: 'Fair Wear works with brands and industry influencers to improve working conditions where your clothing is made.'
            },
            {
                hid: 'og:description',
                name: 'og:description',
                content: 'Fair Wear works with brands and industry influencers to improve working conditions where your clothing is made.'
            },
            { hid: 'og:site_name', name: 'og:site_name', content: 'Fair Wear' },
            { hid: 'og:title', name: 'og:site_name', content: 'Fair Wear' }

        ],
        link: [
            { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
            { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
            { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
            {
                rel: 'stylesheet',
                href: 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,700;1,400;1,700&display=swap'
            },
            { rel: 'stylesheet', href: 'https://cloud.typography.com/7885516/7969612/css/fonts.css' }
        ]
    },

    /*
     ** Customize the progress-bar color
     */
    loading: {
        color: '#ed003b',
        failedColor: '#000',
        height: '3px',
        throttle: 100,
        duration: 2000,
        continuous: true
    },

    /*
     ** Global CSS
     */
    css: [
        '~/assets/styles/global.less'
    ],

    /*
     ** Plugins to load before mounting the App
     */
    plugins: [
        '~/plugins/seo/vue-seo',
        '~/plugins/cookie-consent',
        '~/plugins/analytics.js'
    ],

    /*
     ** Customize the sitemap (@nuxtjs/sitemap)
     */
    sitemap: [
        {
            path: '/sitemap.xml',
            hostname: siteConfig.self.url,
            gzip: true,
            sitemaps: [
                {
                    path: '/sitemap-static.xml',
                    exclude: [
                        '/styleguide',
                        '/styleguide/**'
                    ],
                    routes: pageRoutes
                },
                {
                    path: '/brands/sitemap.xml',
                    routes: brandRoutes,
                    exclude: ['/**']
                },
                {
                    path: '/country/sitemap.xml',
                    routes: countryRoutes,
                    exclude: ['/**']
                },
                {
                    path: '/stories/sitemap.xml',
                    routes: storyRoutes,
                    exclude: ['/**']
                },
                {
                    path: '/programmes/sitemap.xml',
                    routes: programRoutes,
                    exclude: ['/**']
                },
                {
                    path: '/resource/sitemap.xml',
                    routes: resourceRoutes,
                    exclude: ['/**']
                },
                // {
                //     path: '/term/sitemap.xml',
                //     routes: termRoutes,
                //     exclude: ['/**']
                // },
                {
                    path: '/tool/sitemap.xml',
                    routes: toolRoutes,
                    exclude: ['/**']
                }
            ]
        }
    ],

    /*
     ** Nuxt.js modules
     */
    modules: [
        // Doc: https://axios.nuxtjs.org/usage
        '@nuxtjs/axios',
        '@nuxtjs/pwa',
        '@nuxtjs/svg',
        '@nuxtjs/style-resources',
        '~/modules/bugsnag',
        ['dn-nuxt-config', {
            useBabelRc: false
        }],
        '@nuxtjs/sitemap',
        '@nuxtjs/proxy'
    ],

    /*
     ** Bugsnag configuration
     */
    bugsnag: {
        serverApiKey: siteConfig.bugsnagId || '',
        browserApiKey: siteConfig.bugsnagId || '',
        releaseStage: process.env.APP_ENV || 'development'
    },

    /*
     **
     */
    serverMiddleware: [
        '~/middleware/seo.js'
    ],

    /*
     ** Axios module configuration
     */
    axios: {
        // See https://github.com/nuxt-community/axios-module#options
        baseURL: siteConfig.api.url,
        memberHubURL: siteConfig.api.memberHub
    },
    proxy: {
        // Simple proxy
        '/public/services/apexrest': 'https://fwf.my.site.com/'
    },
    /*
     ** Style-resources module configuration
     */
    styleResources: {
        // your settings here
        less: [
            '~/assets/styles/_variables/**/*.less',
            '~/assets/styles/_mixins/**/*.less'
        ]
    },

    /*
     ** Build
     */
    build: {
        transpile: [
            ({ isServer, isClient, isLegacy }) => (isServer || (isClient && isLegacy)) && 'vue-instantsearch',
            ({ isServer, isClient, isLegacy }) => (isServer || (isClient && isLegacy)) && 'instantsearch.js/es'
        ],
        babel: {
            plugins: ['@babel/plugin-proposal-optional-chaining']
        },
    }
};
