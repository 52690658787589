<template>
    <svg
        class="to-top"
        width="24px"
        height="23px"
        viewBox="0 0 24 23"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="A_Primer/Icon/Icon-Backtotop" transform="translate(-4.000000, -5.000000)" fill="#FFFFFF">
                <path d="M17.0699247,14.4426811 L17.0699247,27 C17.0699247,27.5522847 16.6222094,28 16.0699247,28 L15.5679498,28 C15.0156651,28 14.5679498,27.5522847 14.5679498,27 L14.5679498,14.4450459 L7.63081952,21.3821763 C7.24029522,21.7727005 6.60713025,21.7727005 6.21660595,21.3821763 L5.86165614,21.0272264 C5.47113185,20.6367022 5.47113185,20.0035372 5.86165614,19.6130129 L15.1130129,10.3616561 C15.5035372,9.97113185 16.1367022,9.97113185 16.5272264,10.3616561 L25.7785832,19.6130129 C26.1691075,20.0035372 26.1691075,20.6367022 25.7785832,21.0272264 L25.4236334,21.3821763 C25.0331091,21.7727005 24.3999441,21.7727005 24.0094198,21.3821763 L17.0699247,14.4426811 Z M5.25,5 L26.75,5 C27.4403559,5 28,5.55964406 28,6.25 C28,6.94035594 27.4403559,7.5 26.75,7.5 L5.25,7.5 C4.55964406,7.5 4,6.94035594 4,6.25 C4,5.55964406 4.55964406,5 5.25,5 Z" />
            </g>
        </g>
    </svg>
</template>

<script>

export default {
};

</script>

<style lang="less" scoped>
    @import 'to-top.less';
</style>
