<template>
    <div class="search-results">
        <ais-stats class="search-results__hero">
            <h2 slot-scope="{ nbHits }" class="search-results__heading">
                <span class="search-results__heading--long">
                    {{ titleRendered }}
                </span>
                <span class="search-results__heading--short">
                    {{ titleShortRendered }}
                </span>
                <span class="search-results__amount">
                    {{ nbHits }}
                </span>
            </h2>
        </ais-stats>
        <div v-if="visible" class="search-results__filter">
            <h3 class="search-results__filter-title">Filter results</h3>
            <ais-hierarchical-menu
                :attributes="['hierarchy.lvl0', 'hierarchy.lvl1']"
                class="search-results__filter-items"
                :root-path="rootPath"
                :show-parent-level="true"
            />
        </div>
        <ais-state-results class="search-results__items">
            <ais-infinite-hits>
                <div
                    slot-scope="{ items, refineNext, isLastPage }"
                >
                    <search-result v-for="item in items" :key="item.id" :item="item" class="search-results__item" @closeResults="closeResults" />

                    <button v-if="!isLastPage" class="search-results__more btn btn--white btn--arrow-down btn--wide" @click="refineNext">
                        Show more results
                    </button>
                </div>
            </ais-infinite-hits>
        </ais-state-results>
    </div>
</template>

<script>
import {
    AisInfiniteHits,
    AisStateResults,
    AisHierarchicalMenu,
    AisStats
} from 'vue-instantsearch';
import SearchResult from '~/patterns/atoms/search-result/search-result';

export default {

    components: {
        AisInfiniteHits,
        AisStateResults,
        AisHierarchicalMenu,
        AisStats,
        SearchResult
    },

    props: {
        title: {
            default: '',
            type: String
        },
        titleShort: {
            default: 'Results',
            type: String
        },
        rootPath: {
            default: '',
            type: String
        },
        visible: {
            default: false,
            type: Boolean
        }
    },

    computed: {
        titleRendered() {
            if (this.title !== '') {
                return this.title;
            }

            return 'Search results';
        },

        titleShortRendered() {
            if (this.titleShort !== '') {
                return this.titleShort;
            } else if (this.title !== '') {
                return this.title;
            }

            return 'Search';
        }
    },

    methods: {
        closeResults() {
            this.$emit('closeResults');
        }
    }
};
</script>

<style lang="less" scoped>
    @import 'search-results';
</style>
