<template>
    <div class="cta-movement">
        <div class="cta-movement__items">
            <h3 class="cta-movement__title">Ready to take action for a <em>fairer garment industry?</em></h3>

            <nuxt-link to="/join-the-movement" class="cta-movement__btn btn btn--white">
                Join the movement
            </nuxt-link>
        </div>
<!--        <div class="cta-movement__brands">-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/zeeman_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/suitsupply_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/nudie_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/filippak_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/zeeman_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/suitsupply_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/nudie_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/filippak_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/zeeman_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/suitsupply_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/nudie_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/filippak_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/zeeman_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/suitsupply_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/nudie_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/filippak_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/zeeman_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/suitsupply_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/nudie_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/filippak_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/zeeman_logo.png">-->
<!--            </div>-->
<!--            <div class="cta-movement__brand">-->
<!--                <img class="cta-movement__brand-image" src="/logos/suitsupply_logo.png">-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>
<script>
export default {

};
</script>
<style lang="less">
.cta-movement {
    background: @color-quaternary;
    max-width: 100svw;
    overflow: hidden;
}

.cta-movement__items {
    text-align: center;
    padding: 5rem 1rem;

    background-image: url('@{image-path}/decoration/pattern-pink.png');
}

.cta-movement__brands {
    padding: 3rem 0;
    display: flex;
    gap: 3rem;
}

.cta-movement__title {
    font-size: 3rem;
    font-weight: 700;
    max-width: 40rem;
    margin: 0 auto 3rem;
}

.cta-movement__btn {
    background-color: #fff;
    width: 100%;
    text-align: left;

    @media @q-sm-min {
        width: auto;
        text-align: center;
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-574.667px);
    }
}

.cta-movement__brands {
   animation: scroll 10s linear infinite;
   padding: 3rem 0;
}
.cta-movement__brand-image {
    height: 1rem;
}
</style>
