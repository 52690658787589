<template>
    <div>
        <cta-movement v-if="$nuxt.$route.path !== '/'" />

        <main-footer />
        <dn-cookiebar class="fw-cookiebar" role="dialog">
            <template v-slot="{ acceptAllCookies, dismissCookiebar }">
                <div class="dn-cookiebar__header">
                    <h3>Cookies</h3>
                </div>

                <div class="dn-cookiebar__message">
                    <p>
                        We use cookies to provide you with the best experience of our
                        website. Read more about our
                        <nuxt-link class="link" to="/privacy-policy">cookie regulations</nuxt-link>
                        here.
                    </p>
                </div>

                <div class="dn-cookiebar__buttons">
                    <a
                        class="dn-cookiebar__button dn-cookiebar__button-accept"
                        @click.prevent="acceptAllCookies()"
                    >
                        <span class="btn__label">Fair enough</span>
                    </a>

                    <a
                        class="dn-cookiebar__button dn-cookiebar__button-dismiss"
                        @click.prevent="dismissCookiebar()"
                    >
                        <span class="btn__label">Don&rsquo;t use cookies</span>
                    </a>
                </div>
            </template>
        </dn-cookiebar>
    </div>
</template>

<script>
import { DnCookiebar } from '@digitalnatives/cookie-consent';
import MainFooter from '~/patterns/molecules/main-footer/main-footer';
import CtaMovement from '~/patterns/molecules/cta-movement/cta-movement.vue';

export default {
    components: {
        DnCookiebar,
        MainFooter,
        CtaMovement
    }
};

</script>
