// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/icons/chevron-right-tertiary.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body[data-v-008d03d4]{min-height:100vh}.font-primary[data-v-008d03d4],.font-primary-regular[data-v-008d03d4]{font-family:Barlow,helvetica,arial,sans-serif;font-weight:400}.breadcrumbs[data-v-008d03d4]{margin-bottom:.625rem;margin-top:2.25rem}@media screen and (min-width:981px){.breadcrumbs[data-v-008d03d4]{margin-top:1.25rem}}.breadcrumbs__item[data-v-008d03d4]{display:inline-block;font-family:\"Archer A\",\"Archer B\",\"Trebuchet MS\",\"Times New Roman\",serif;font-size:.87rem;list-style:none;margin:0;padding:0}@media screen and (min-width:521px){.breadcrumbs__item[data-v-008d03d4]{font-size:1rem}}@media screen and (min-width:722px){.breadcrumbs__item[data-v-008d03d4]{font-size:1rem}}@media screen and (min-width:981px){.breadcrumbs__item[data-v-008d03d4]{font-size:1rem}}@media screen and (min-width:1281px){.breadcrumbs__item[data-v-008d03d4]{font-size:1rem}}@media screen and (min-width:1441px){.breadcrumbs__item[data-v-008d03d4]{font-size:1rem}}.breadcrumbs__item[data-v-008d03d4]:before{content:none}.breadcrumbs__item[data-v-008d03d4]:after{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50% 50%;background-repeat:no-repeat;background-size:cover;content:\"\";display:inline-block;height:1rem;transform:translateY(.2rem);width:1rem}.breadcrumbs__item[data-v-008d03d4]:last-child{font-style:italic}.breadcrumbs__item:last-child .breadcrumbs__link[data-v-008d03d4]{cursor:default}.breadcrumbs__item[data-v-008d03d4]:last-child:after{content:none}.breadcrumbs__link[data-v-008d03d4]{color:#707070;-webkit-text-decoration:none;text-decoration:none}.breadcrumbs__link[data-v-008d03d4]:hover{font-style:italic}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
