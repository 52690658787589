<template>
    <div class="hero" :class="modifier">
        <div v-show="image" class="hero__image">
            <img
                :src="image"
                alt=""
                loading="eager"
            >
        </div>
        <div class="hero__content">
            <div>
                <breadcrumbs :current="current" :archive="archive" class="hero__breadcrumbs" />

                <h1 class="hero__title" v-html="title" /> <!-- eslint-disable-line vue/no-v-html -->
            </div>
            <slot />
        </div>
    </div>
</template>

<script>

import Breadcrumbs from '~/patterns/molecules/breadcrumbs/breadcrumbs.vue';

export default {
    components: {
        Breadcrumbs
    },
    props: {
        title: {
            type: String,
            default() {
                return '';
            }
        },
        image: {
            type: String,
            default: ''
        },
        modifierClass: {
            type: String,
            default: ''
        },
        current: { // for displaying breadcrumbs with ancestors of current page
            type: Object,
            default: () => {
                return {};
            }
        },
        archive: { // for forcing the breadcrumbs to display an archive page
            type: String,
            default: ''
        }
    },
    head() {
        return {
            meta: [
                {
                    property: 'og:image',
                    content: this.image
                }
            ]
        };
    },
    computed: {
        // a computed getter
        modifier() {
            const modifiers = ['primary', 'secondary', 'secondary-dark', 'tertiary', 'quaternary'];
            let dataModifier = this.modifierClass;
            let randomModifier = modifiers[Math.floor(Math.random() * modifiers.length)]; // random modifier

            if (!this.image) {
                dataModifier += ' hero--no-image';
                randomModifier += ' hero--no-image';
            }

            return 'hero--' + (dataModifier === '' ? randomModifier : dataModifier); // random modifier
        }
    }
};
</script>

<style lang="less" scoped>
    @import 'hero.less';
</style>
