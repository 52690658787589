import developmentConfig from './development.js';
import productionConfig from './production.js';
import stagingConfig from './staging.js';

const env = process.env.APP_ENV || 'production';


const config = {
    gtmId: '',
    bugsnagId: ''
};

const localizedConfigs = {
    production: productionConfig,
    staging: stagingConfig,
    development: developmentConfig
};

export default Object.assign(config, localizedConfigs[env]);
