<template>
    <div>
        <slot :query="query" :refine="refine">
            <input v-model="query" type="search" v-bind="$attrs" v-on="$listeners">
        </slot>
    </div>
</template>

<script>
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { createWidgetMixin } from 'vue-instantsearch';
import debounce from 'lodash/debounce';

export default {
    mixins: [
        createWidgetMixin({ connector: connectSearchBox })
    ],
    props: {
        delay: {
            type: Number,
            default: 200,
        },
        leading: {
            type: Boolean,
            default: false
        },
        trailing: {
            type: Boolean,
            default: true
        },
        maxWait: {
            type: Number,
            default: 2000,
        },
    },
    data() {
        return {
            localQuery: '',
            debouncedSearch: debounce(this.search, this.delay, {
                leading: this.leading,
                trailing: this.trailing,
                maxWait: this.maxWait
            })
        };
    },
    computed: {
        query: {
            get() {
                return this?.localQuery ?? '';
            },
            set(value) {
                this.refine(value);
            }
        }
    },
    methods: {
        refine(value) {
            // debugger
            this.localQuery = value;
            this.debouncedSearch();
        },
        search() {
            // debugger
            this?.state?.refine(this?.localQuery ?? '');
        }
    }
};
</script>
