<template>
    <ul v-if="!loading && (current.id || archive !== '')" class="breadcrumbs">
        <li v-for="item in items" :key="item.id" class="breadcrumbs__item" :data-id="item.id" :data-current="current.id">
            <nuxt-link :to="makeRelative(item.permalink)" class="breadcrumbs__link" :title="item.title">
                <template v-if="item.title.length < 31 || item.id.endsWith(current.id)">
                    {{ item.title }}
                </template>
                <template v-else>
                    {{ item.title.substring(0, 30) }}…
                </template>
            </nuxt-link>
        </li>
    </ul>
</template>

<script>

import WPAPI from '~/api/wordpress';
import { makeRelative } from '~/utils/url.js';

export default {

    props: {
        current: { // for displaying breadcrumbs with ancestors of current page
            type: Object,
            default: () => {
                return {};
            }
        },
        archive: { // for forcing the breadcrumbs to display an archive page
            type: String,
            default: ''
        }
    },

    data() {
        return {
            loading: true,
            ancestors: [],
            types: {
                program: {
                    id: 1,
                    route: '/programmes',
                    rootTitle: 'Our impact',
                    rootPath: '/our-impact'
                },
                post: {
                    id: 2,
                    route: '/stories',
                    rootTitle: 'Stories',
                    rootPath: '/stories',
                    parent: 'program'
                },
                complaint: {
                    id: 3,
                    route: '/complaints',
                    rootTitle: 'Complaints',
                    rootPath: '/programmes/complaints',
                    parent: 'program'
                },
                resource: {
                    id: 4,
                    route: '/resource-documents',
                    rootTitle: 'Resource documents',
                    rootPath: '/resources-and-tools/resource-documents',
                    parent: 'resources-and-tools'
                },
                tool: {
                    id: 5,
                    route: '/resources-and-tools',
                    rootTitle: 'Resources and tools',
                    rootPath: '/resources-and-tools'
                },
                brand: {
                    id: 6,
                    route: '/brands',
                    rootTitle: 'Fair Wear brands',
                    rootPath: '/brands'
                },
                country: {
                    id: 7,
                    route: '/countries',
                    rootTitle: 'Countries',
                    rootPath: '/programmes/countries',
                    parent: 'program'
                },
                'former-members': {
                    id: 8,
                    route: '/brands/former-members',
                    rootTitle: 'Former members',
                    rootPath: '/brands',
                    parent: 'brand'
                },
                'resources-and-tools-factories': {
                    id: 9,
                    route: { name: 'resources-and-tools-factories' },
                    rootTitle: 'Factories our members source from',
                    rootPath: { name: 'resources-and-tools-factories' }
                },
                'resources-and-tools-factories-factory-contact': {
                    id: 10,
                    route: { name: 'resources-and-tools-factories-factory-contact' },
                    rootTitle: 'Complaints',
                    rootPath: { name: 'resources-and-tools-factories-factory-contact' },
                    parent: 'resources-and-tools-factories'
                }
            }
        };
    },

    async fetch() {
        if (this.current.id && !this.current.company) {
            await WPAPI.getBreadcrumbs(this.current.id, this.current.slug).then((response) => {
                this.ancestors = response.reverse();
                this.loading = false;
            });
        }
    },

    computed: {
        items() {
            // debugger
            // init
            const items = [];
            let postType = this.current.type;

            if (this.current.company) {
                postType = 'brand';
            }

            // items
            if (this.current.id) {
                items.push({
                    id: 'current' + this.current.id,
                    title: this.getTitle(this.current),
                    permalink: this.getPermalink(this.current)
                });
            }

            // ancestors
            this.ancestors.forEach((item) => {
                items.push({
                    id: 'ancestor' + item.id,
                    title: this.getTitle(item),
                    permalink: this.getPermalink(item)
                });
            });

            // post type root
            if (typeof this.types[postType] !== 'undefined' || typeof this.types[this.archive] !== 'undefined') {
                const root = this.current.id ? this.types[postType] : this.types[this.archive];
                items.push({
                    id: 'root' + root.id,
                    title: root.rootTitle,
                    permalink: root.rootPath.name || root.rootPath
                });

                // überroot
                if (typeof root.parent !== 'undefined') {
                    const parent = this.types[root.parent];
                    items.push({
                        id: 'uberroot' + parent.id,
                        title: parent.rootTitle,
                        permalink: parent.rootPath.name || parent.rootPath
                    });
                }
            }

            return items.reverse();
        }
    },

    methods: {
        getTitle(item) {
            if (item.company) {
                return item.name;
            }

            let title = typeof item.meta !== 'undefined' && typeof item.meta.short_title !== 'undefined' && item.meta.short_title !== '' ? item.meta.short_title : item.title && item.title.rendered ? item.title.rendered : '';
            if (typeof title === 'object') {
                title = title[0];
            }

            return title.replace('&amp;', '&');
        },

        getPermalink(item) {
            return item.permalink || ((typeof this.types[item.type] !== 'undefined' ? this.types[item.type].route : '') + '/' + item.slug);
        },
        makeRelative
    }
};
</script>

<style lang="less" scoped>
@import 'breadcrumbs';
</style>
