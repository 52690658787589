<template>
    <div
        class="layout"
        :class="{
            'layout--navigation-expanded': navigationExpanded,
            'layout--search-expanded': searchExpanded
        }"
    >
        <site-header
            @navigation-expanded="expandNavigation"
            @navigation-collapsed="collapseNavigation"
            @search-expanded="expandSearch"
            @search-collapsed="collapseSearch"
        />

        <nuxt />

        <div class="layout__footer">
            <site-footer />
        </div>
    </div>
</template>

<script>

import SiteHeader from '~/patterns/organisms/site-header/site-header';
import SiteFooter from '~/patterns/organisms/site-footer/site-footer';

export default {
    components: {
        SiteHeader,
        SiteFooter
    },

    data() {
        return {
            navigationExpanded: false,
            searchExpanded: false
        };
    },

    methods: {
        expandNavigation() {
            this.navigationExpanded = true;
        },

        collapseNavigation() {
            this.navigationExpanded = false;
        },

        expandSearch() {
            this.searchExpanded = true;
        },

        collapseSearch() {
            this.searchExpanded = false;
        }
    }

};
</script>
