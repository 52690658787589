'use strict';

import {
    brands,
    countries,
    generalPages,
    stories,
    programs,
    resources,
    terms,
    tools
} from '../api/sitemap.js';
import { makeRelative } from '../utils/url';

const mapRoutes = (pages) => {
    if (!pages) {
        return [{ path: '/404' }];
    }

    return pages.map(({ url }) => makeRelative(url));
};

/**
 * Return an array of objects containing sitemap records to use for generating sitemaps.
 */
export async function brandRoutes()
{
    // Brands was originally Members
    const brandPages = await brands();

    return mapRoutes(brandPages);
}

export async function countryRoutes()
{
    const countryPages = await countries();

    return mapRoutes(countryPages);
}

export async function pageRoutes()
{
    const pages = await generalPages();

    return mapRoutes(pages);
}

export async function storyRoutes()
{
    const storyPages = await stories();

    return mapRoutes(storyPages);
}

export async function programRoutes()
{
    const programPages = await programs();

    return mapRoutes(programPages);
}

export async function resourceRoutes()
{
    const resourcePages = await resources();

    return mapRoutes(resourcePages);
}

export async function termRoutes()
{
    const termPages = await terms();

    return mapRoutes(termPages);
}

export async function toolRoutes()
{
    const toolPages = await tools();

    return mapRoutes(toolPages);
}
