<template>
    <nuxt-link :to="relative_permalink" class="search-result" @click.native="closeResults">
        <span v-if="item.result_image" class="search-result__image" :style="style" />

        <p class="search-results__title">
            <ais-highlight :hit="item" highlighted-tag-name="em" attribute="post_title" />
        </p>

        <span class="search-result__type">
            {{ item.post_type_single }}
        </span>
    </nuxt-link>
</template>

<script>
import { AisHighlight } from 'vue-instantsearch';
import { makeRelative } from '~/utils/url.js';

export default {

    components: {
        AisHighlight
    },

    props: {
        item: {
            default: () => {
                return {
                    post_type: '',
                    post_name: '',
                    result_image: '',
                    post_title: '',
                    post_type_single: ''
                };
            },
            type: Object
        },
        rootPath: {
            default: '',
            type: String
        }
    },

    computed: {
        relative_permalink() {
            return makeRelative(this.item.permalink);
        },
        style() {
            return `background-image: url(${this.item.result_image})`;
        }
    },

    methods: {
        closeResults() {
            this.$emit('closeResults');
        }
    }
};
</script>

<style lang="less" scoped>
    @import 'search-result';
</style>
