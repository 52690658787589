'use strict';

import axios from '../plugins/axios.js';
import config from '../nuxt.config';

const getResults = async function(postType) {
    const baseUrl = config.axios.baseURL;

    return await axios
        .get(baseUrl + '/wp-json/fwf/v2/seo', {
            params: {
                post_type: postType
            }
        })
        .then(response => response.data);
};

export const brands = () => {
    return getResults('brand');
};

export const countries = () => {
    return getResults('country');
};

export const generalPages = () => {
    return getResults('page');
};

export const stories = () => {
    return getResults('post');
};

export const programs = () => {
    return getResults('program');
};

export const resources = () => {
    return getResults('resource');
};

export const terms = () => {
    return getResults('term');
};

export const tools = () => {
    return getResults('tool');
};
