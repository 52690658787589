'use strict';

class SEOService {
    getMetaData(seo = {}) {
        // console.log(seo);

        const metaData = {
            title: seo.title,
            meta: [
                { hid: 'description', name: 'description', 'content': seo.description },
                ...this.getFbFields(seo),
                ...this.getTwFields(seo)
            ]
        };

        // metaData.meta.push[]

        return metaData;
    }

    getFbFields(seo) {
        return Object.keys(seo.social.facebook).map((key) => {
            return { hid: key, name: key, 'content': seo.social.facebook[key] };
        });
    }

    getTwFields(seo) {
        return Object.keys(seo.social.twitter).map((key) => {
            return { hid: key, name: key, 'content': seo.social.twitter[key] };
        });
    }
}

export default {
    install(VueInstance) {
        VueInstance.prototype.$seo = VueInstance.$seo = new SEOService();
    }
};
