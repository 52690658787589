'use strict';

import { isEmpty } from 'lodash';
import wordpressApi, { getPostById, getFrontPage } from '~/api/wordpress';

export const state = () => ({
    pages: [],
    currentPage: {},
    parentPage: [],
    home: {}
});

export const getters = {
    pages: ({ pages }) => pages,
    currentPage: ({ currentPage }) => currentPage,
    parentPage: myState => myState.parentPage,
    home: ({ home }) => home
};

export const actions = {
    getBySlug(context, slug) {
        return new Promise((resolve) => {
            wordpressApi.getPage(context, slug)
                .then((response) => {
                    if (!response.id) {
                        response.slug = slug;
                        response.errors = true;
                    }

                    context.commit('add', response);
                    return resolve(response);
                }).catch((error) => {
                    // eslint-disable-next-line
                    console.log(error);
                    const page = {
                        slug,
                        errors: true
                    };
                    context.commit('add', page);
                    return resolve(page);
                });
        });
    },
    getHome({ dispatch, getters: myGetters }) {
        const page = myGetters.home;

        if (!isEmpty(page)) {
            return page;
        }

        return dispatch('fetchHome');
    },
    getByPageId({ dispatch, getters: myGetters, state: myState }, { id }) {
        const parentPage = myGetters.parentPage;

        if (parentPage.length > 0) {
            return parentPage;
        }

        return dispatch('fetchByPageId', { id });
    },
    async fetchHome({ commit }) {
        const page = await getFrontPage();

        if (!isEmpty(page)) {
            commit('setHome', page);
        }

        return page;
    },
    async fetchByPageId({ commit }, { id }) {
        const parentPage = await getPostById(id);

        if (!isEmpty(parentPage)) {
            commit('setParentPage', parentPage);
        }

        return parentPage;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    set(myState, pages) {
        myState.pages = pages;
    },
    setPage(myState, page) {
        myState.currentPage = page;
    },
    setParentPage(myState, parentPage) {
        myState.parentPage = parentPage;
    },
    setHome(myState, page) {
        myState.home = page;
    },
    add(myState, page) {
        const pageExists = myState.pages.find(findPage => page.id === findPage.id);

        if (!pageExists) {
            myState.pages.push(page);
        }
    },
    clearCache(myState) {
        myState.pages = [];
        myState.currentPage = [];
        myState.parentPage = [];
    }
};
